<template>
  <div class="schedules-expansion q-mt-lg">
    <mi-expansion-item
      expand-icon-toggle
      expand-separator
      label="Department schedules"
      :model-value="shouldExpand"
      @update:model-value="handleExpansionChange"
    >
      <template #header>
        <slot name="header"></slot>
      </template>
      <slot name="body"></slot>
    </mi-expansion-item>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'

  export default {
    name: 'SchedulesExpansion',
    props: {
      departmentName: {
        type: String,
        default: ''
      }
    },
    setup(props) {
      const shouldExpand = ref(false)

      const getStoredDepartments = () => {
        const stored = localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_SCHEDULE_DEPARTMENT)
        return stored ? JSON.parse(stored) : []
      }

      const checkDepartment = () => {
        const storedDepartments = getStoredDepartments()
        shouldExpand.value = storedDepartments.includes(props.departmentName)
      }

      const handleExpansionChange = isExpanded => {
        const storedDepartments = getStoredDepartments()

        if (isExpanded) {
          if (!storedDepartments.includes(props.departmentName)) {
            storedDepartments.push(props.departmentName)
          }
        }
        else {
          const index = storedDepartments.indexOf(props.departmentName)
          if (index > -1) {
            storedDepartments.splice(index, 1)
          }
        }

        localStorage.setItem(process.env.VUE_APP_STORAGE_KEY_SCHEDULE_DEPARTMENT, JSON.stringify(storedDepartments))
        shouldExpand.value = isExpanded
      }

      onMounted(() => {
        checkDepartment()
      })

      return {
        shouldExpand,
        handleExpansionChange
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep(.mi-expansion-item__expand-icon) .q-expansion-item__toggle-icon {
    font-size: 16px;
    color: $mi-anthracite-800;
  }

  .schedules-expansion {
    background-color: $mi-white;
  }
</style>
